import {
    NO_MORE_LIKED_POSTS,
    NO_MORE_USER_POSTS,
    RESET_USER_POSTS,
    SET_PROFILE_SCROLL_POSITION,
    USER_LIKED_POSTS_FETCH_SUCCESS,
    USER_POSTS_FETCH_SUCCESS,
    USER_PROFILE_FETCH_SUCCESS
} from "../../../actions/types";

const INITIAL_STATE = {
    lastUserPostsCursor: null, lastLikedPostsCursor: null,
    scrollPosition: 0, profile: {}, hasMoreLikedPosts: true, hasMoreUserPosts: true
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case USER_POSTS_FETCH_SUCCESS:
            return {
                ...state,
                lastUserPostsCursor: action.cursor,
                hasMoreUserPosts: action.hasMore
            };
        case USER_LIKED_POSTS_FETCH_SUCCESS:
            return {
                ...state, lastLikedPostsCursor: action.cursor,
                hasMoreLikedPosts: action.hasMore
            };
        case NO_MORE_USER_POSTS:
            return {...state, hasMoreUserPosts: false}
        case NO_MORE_LIKED_POSTS:
            return {...state, hasMoreLikedPosts: false}
        case RESET_USER_POSTS:
            return {...INITIAL_STATE};
        case SET_PROFILE_SCROLL_POSITION:
            return {...state, scrollPosition: action.value};
        case USER_PROFILE_FETCH_SUCCESS:
            return {...state, profile: action.payload}
        default:
            return state;
    }
}