import {POST_COMMENTS_FETCH_SUCCESS, RESET_REDUCER_STATE} from "../../../actions/types";

const INITIAL_STATE = {comments: {}};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case RESET_REDUCER_STATE:
            return {...INITIAL_STATE};

        case POST_COMMENTS_FETCH_SUCCESS:
            let postCommentObject = {}
            postCommentObject[action.postId] = action.payload;
            return {...state, comments: {...state.comments, ...postCommentObject}}

        default:
            return state;
    }
}