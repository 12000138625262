import {applyMiddleware, createStore} from 'redux'
import thunkMiddleware from 'redux-thunk'
import reducers from "./reducers";
import {composeWithDevTools} from "redux-devtools-extension";
import {useMemo} from "react";
import merge from 'lodash/merge'

let store

function initStore(initialState) {
    return createStore(
        reducers,
        initialState,
        composeWithDevTools(applyMiddleware(thunkMiddleware))
    )
}

export const initializeStore = (preloadedState) => {
    let _store = store ?? initStore(preloadedState)

    // After navigating to a page with an initial Redux state, merge that state
    // with the current state in the store, and create a new store
    if (preloadedState && store) {

        let combinedServerClientState = merge(store.getState(), preloadedState);
        _store = initStore(combinedServerClientState)

        // Reset the current store
        store = undefined
    }

    // For SSG and SSR always create a new store
    if (typeof window === 'undefined') return _store

    // Create the store once in the client
    if (!store) store = _store

    return _store
}

export function useStore(initialState) {
    return useMemo(() => initializeStore(initialState), [initialState])
}