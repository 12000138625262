import {auth, storage} from "../config/firebase";
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import cuid from "cuid";
import {getDownloadURL, ref, uploadBytes} from "firebase/storage";
import useSWR from "swr";
import {search} from "./trends";

export const approx = (num, digits) => {
    let si = [
        {value: 1, symbol: ""},
        {value: 1E3, symbol: "k"},
        {value: 1E6, symbol: "m"},
        {value: 1E9, symbol: "B"},
        {value: 1E12, symbol: "T"},
        {value: 1E15, symbol: "P"},
        {value: 1E18, symbol: "E"}
    ];
    let rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    let i;
    for (i = si.length - 1; i > 0; i--) {
        if (num >= si[i].value) {
            break;
        }
    }
    return (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;
};

export const objectToArray = (object) => {
    if (isEmpty(object)) {
        return []
    }
    return Object.entries(object).map(e => Object.assign(e[1], {id: e[0]}))
};


export const getUserId = () => {
    return get(auth, 'currentUser.uid', "");
};

export const getUsername = () => {
    return get(auth, 'currentUser.displayName', "");
};


export const extractYoutubeId = (url) => {
    let regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    let match = url.match(regExp);
    if (match && match[2].length === 11) {
        return match[2];
    }
}

export const uploadPhotoAsync = async (postRefId, image) => {
    const filename = cuid() + '.' + getFileExtension(image.name);
    const path = `/posts/${postRefId}/${filename}`;
    const imgUrl = await uploadPhoto(image.preview, path);

    // return {
    //     'caption': "",
    //     'url': imgUrl
    // };
    return imgUrl
};

export function getFileExtension(filename) {
    return filename.slice((filename.lastIndexOf('.') - 1 >>> 0) + 2);
}

export const uploadPhoto = async (uri, path) => {
    const blob = await urlToBlob(uri);
    const storageRef = ref(storage, path)

    return new Promise((res) => {
        uploadBytes(storageRef, blob).then((snapshot) => {
            getDownloadURL(snapshot.ref).then((downloadURL) => {
                res(downloadURL)
            });
        });
    })
};

export const urlToBlob = (url) => {
    return new Promise((resolve, reject) => {
        let xhr = new XMLHttpRequest();
        xhr.onerror = reject;
        xhr.onreadystatechange = () => {
            if (xhr.readyState === 4) {
                resolve(xhr.response);
            }
        };
        xhr.open('GET', url);
        xhr.responseType = 'blob'; // convert type
        xhr.send();
    })
};

export const createDataTree = (dataset, originalPosterId, ftcId) => {
    // dataset = orderBy(dataset, ['diffVoteCnt'], ['desc']);
    let hashTable = Object.create(null);
    dataset.forEach(a => hashTable[a.id] = {...a, replies: []});
    let dataTree = [];
    dataset.forEach(a => {
        if (a.aId === originalPosterId) hashTable[a.id]['isOP'] = true;
        if (a.aId === ftcId) hashTable[a.id]['isFTC'] = true;
        if (a.pId) hashTable[a.pId].replies.push(hashTable[a.id]);
        else dataTree.push(hashTable[a.id])
    });
    return dataTree

};

export const extractPost = querySnap => {
    let newPosts = {};
    for (let i = 0; i < querySnap.docs.length; i++) {
        let post = querySnap.docs[i].data();
        post['timeStamp'] = post['timeStamp'].toMillis()
        post['id'] = querySnap.docs[i].id
        newPosts[querySnap.docs[i].id] = post;
    }
    return newPosts
};


export const timeSince = (date) => {
    if (typeof date !== 'object') {
        date = new Date(date);
    }

    const seconds = Math.floor((new Date() - date) / 1000);
    let intervalType;

    let interval = Math.floor(seconds / 31536000);
    if (interval >= 1) {
        intervalType = 'y';
    } else {
        interval = Math.floor(seconds / 2592000);
        if (interval >= 1) {
            intervalType = 'mo';
        } else {
            interval = Math.floor(seconds / 86400);
            if (interval >= 1) {
                intervalType = 'd';
            } else {
                interval = Math.floor(seconds / 3600);
                if (interval >= 1) {
                    intervalType = "h";
                } else {
                    interval = Math.floor(seconds / 60);
                    if (interval >= 1) {
                        intervalType = "m";
                    } else {
                        interval = '';
                        intervalType = "now";
                    }
                }
            }
        }
    }
    return interval + ' ' + intervalType;
};

export const isAuthenticated = () => {
    if (auth.currentUser) {
        return !auth.currentUser.isAnonymous
    }
    return false;
};

export const createReducer = (initialState, fnMap) => {
    return (state = initialState, {type, payload}) => {
        const handler = fnMap[type];
        return handler ? handler(state, payload) : state
    }
}

export const getGroupNames = async () => {
    return await fetcher('https://naija-world-1c442.web.app/api/groupNames')
}

export const getRelatedPosts = async (searchTerm, limit = 20) => {
    return await search(searchTerm, limit)
}

export const useTrends = () => {
    const {data: trends} = useSWR(
        "/api/trends"
    );
    return {trends}
}

export const fetcher = (url) => fetch(url).then((res) => res.json());