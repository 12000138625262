import {initializeApp} from 'firebase/app';
import {getAuth} from 'firebase/auth';
import {getFirestore} from "firebase/firestore/lite";
import {getStorage} from "firebase/storage";
import {getDatabase} from 'firebase/database';
import {getPerformance} from 'firebase/performance';
import {getAnalytics} from 'firebase/analytics';

const clientCredentials = {
    apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
    authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.NEXT_PUBLIC_FIREBASE_DATABASE_URL,
    projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
    storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
    measurementId: process.env.NEXT_PUBLIC_MEASUREMENT_ID
};

const firebaseApp = initializeApp(clientCredentials)

// Check that `window` is in scope for the analytics module!
if (('measurementId' in clientCredentials) && (typeof window !== 'undefined')) {
    getPerformance(firebaseApp)
    getAnalytics(firebaseApp)
}

const auth = getAuth(firebaseApp)
const firestore = getFirestore(firebaseApp)
const storage = getStorage(firebaseApp)
const database = getDatabase(firebaseApp)

export {auth, firestore, storage, database}
