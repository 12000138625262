const SEO = {
    openGraph: {
        type: 'website',
        locale: 'en_NG',
        url: 'https://naijaworld.com',
        site_name: 'NaijaWorld',
    },
    twitter: {
        handle: '@naijaworld_',
        site: '@naijaworld_',
        cardType: 'summary_large_image',
    },
    facebook: {
        appId: '242345596909580'
    }
}

export default SEO