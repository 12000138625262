import {ADD_POST, ADD_POSTS, DELETE_POST, READ_POST, UPDATE_POST} from "../../actions/types";
import omit from 'lodash/omit';
import mapKeys from 'lodash/mapKeys';

const INITIAL_STATE = {};


export default (state = INITIAL_STATE, action) => {

    switch (action.type) {

        case ADD_POST:
            return {...state, [action.postId]: {...action.payload, id: action.postId, timeStamp: action.payload['timeStamp'].toMillis()}};

        case ADD_POSTS:
            return {...state, ...mapKeys(action.payload, 'id')};

        case UPDATE_POST:
            // omit for consistent, consider remove if performance is too slow
            return {...state, [action.payload.id]: action.payload};

        case READ_POST:
            return {...state, [action.payload]: {...state[action.payload], isRead: true}};

        case DELETE_POST:
            return omit(state, action.payload);

        default:
            return state;
    }
}
