import React from 'react';
import {
    GROUP_POSTS_FETCH_SUCCESS,
    NO_MORE_POSTS,
    RESET_REDUCER_STATE,
    SET_GROUP_SCROLL_POSITION
} from "../../../../actions/types";

const INITIAL_STATE = {groupPostIds: [], lastPostCursor: null, scrollPosition: 0, hasMore: false};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GROUP_POSTS_FETCH_SUCCESS:
            return {...state, lastPostCursor: action.cursor, hasMore: action.hasMore};
        case NO_MORE_POSTS:
            return {...state, hasMore: false}
        case RESET_REDUCER_STATE:
            return {...INITIAL_STATE};
        case SET_GROUP_SCROLL_POSITION:
            return {...state, scrollPosition: action.value};
        default:
            return state;
    }
}