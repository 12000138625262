export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_FAIL = 'LOGIN_USER_FAIL';
export const EMAIL_CHANGED = 'EMAIL_CHANGED';
export const PASSWORD_CHANGED = 'PASSWORD_CHANGED';
export const PASSWORD_FOR_VALIDATION_CHANGED = 'PASSWORD_FOR_VALIDATION_CHANGED';
export const SIGNUP_USER_SUCCESS = 'SIGNUP_USER_SUCCESS';
export const SIGNUP_USER_FAIL = 'SIGNUP_USER_FAIL';
export const USERNAME_CHANGED = 'USERNAME_CHANGED';
export const DOB_CHANGED = 'DOB_CHANGED';
export const GENDER_CHANGED = 'GENDER_CHANGED';
export const USER_DETAILS_SAVE_SUCCESS = 'USER_DETAILS_SAVE_SUCCESS';
export const USERNAME_UNAVAILABLE = 'USERNAME_UNAVAILABLE';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAIL = 'RESET_PASSWORD_FAIL';
export const RESET_PASSWORD_BEGIN = 'RESET_PASSWORD_BEGIN';
export const RESET_EMAIL_CHANGED = 'RESET_EMAIL_CHANGED';
export const TRENDING_POSTS_FETCH_SUCCESS = 'TRENDING_POSTS_FETCH_SUCCESS';
export const NEW_POSTS_FETCH_SUCCESS = 'NEW_POSTS_FETCH_SUCCESS';
export const POPULAR_POSTS_FETCH_SUCCESS = 'POPULAR_POSTS_FETCH_SUCCESS';
export const USER_POSTS_FETCH_SUCCESS = 'USER_POSTS_FETCH_SUCCESS';
export const USER_LIKED_POSTS_FETCH_SUCCESS = 'USER_LIKED_POSTS_FETCH_SUCCESS';
export const IMAGE_DESCRIPTION_CHANGED = 'IMAGE_DESCRIPTION_CHANGED';
export const POST_TITLE_CHANGED = 'POST_TITLE_CHANGED';
export const POST_DESCRIPTION_CHANGED = 'POST_DESCRIPTION_CHANGED';
export const GROUP_NAME_CHANGED = 'GROUP_NAME_CHANGED';
export const GROUP_POSTS_FETCH_SUCCESS = 'GROUP_POSTS_FETCH_SUCCESS';
export const ASYNC_ACTION_START = 'ASYNC_ACTION_START';
export const ASYNC_ACTION_FINISH = 'ASYNC_ACTION_FINISH';
export const ASYNC_ACTION_ERROR = 'ASYNC_ACTION_ERROR';
export const NEW_POST_SENT_TO_FIREBASE = 'NEW_POST_SENT_TO_FIREBASE';
export const RESET_REDUCER_STATE = 'RESET_REDUCER_STATE';
export const NEW_POST_FAILED = 'NEW_POST_FAILED';
export const TOGGLE_GRAPHIC_PIC = 'TOGGLE_GRAPHIC_PIC';
export const ADD_IMAGE = 'ADD_IMAGE';
export const YT_SEARCH_TERM_CHANGED = 'YT_SEARCH_TERM_CHANGED';
export const YT_VIDEOS_FETCH_SUCCESSFUL = 'YT_VIDEOS_FETCH_SUCCESSFUL';
export const POST_TYPE_CHANGED = 'POST_TYPE_CHANGED';
export const VIDEO_CHANGED = 'VIDEO_CHANGED';
export const WEBLINK_URL_CHANGED = 'WEBLINK_URL_CHANGED';
export const POST_ID_CHANGED = 'POST_ID_CHANGED';
export const REPLY_SENT = 'REPLY_SENT';
export const REPLY_FAILED = 'REPLY_FAILED';
export const RESET_USER_POSTS = 'RESET_USER_POSTS';
export const UPDATE_POST = 'UPDATE_POST';
export const DELETE_POST = 'DELETE_POST';
export const SUBCRIBED_POSTS_FETCH_SUCCESS = 'SUBCRIBED_POSTS_FETCH_SUCCESS';
export const DELETE_IMAGE = 'DELETE_IMAGE';
export const AUTH_VALIDATION = 'AUTH_VALIDATION';
export const SIGNUP_VALIDATION = 'SIGNUP_VALIDATION';
export const ADD_POST = 'ADD_POST';
export const ADD_POSTS = 'ADD_POSTS';
export const READ_POST = 'READ_POST';
export const POST_COMMENTS_FETCH_SUCCESS = 'POST_COMMENTS_FETCH_SUCCESS';
export const POST_COMMENTS_FETCH_EMPTY = 'POST_COMMENTS_FETCH_EMPTY';
export const POST_COMMENTS_FETCH_FAILURE = 'POST_COMMENTS_FETCH_FAILURE';
export const SET_SCROLL_POSITION = 'SET_SCROLL_POSITION';
export const SET_PROFILE_SCROLL_POSITION = 'SET_PROFILE_SCROLL_POSITION';
export const SET_BOOKMARK_SCROLL_POSITION = 'SET_BOOKMARK_SCROLL_POSITION';
export const SET_GROUP_SCROLL_POSITION = 'SET_GROUP_SCROLL_POSITION';
export const MODAL_OPEN = 'MODAL_OPEN';
export const MODAL_CLOSE = 'MODAL_CLOSE';
export const LOGIN_USER = 'LOGIN_USER';
export const SIGN_OUT_USER = 'SIGN_OUT_USER';
export const USER_PROFILE_FETCH_SUCCESS = 'USER_PROFILE_FETCH_SUCCESS';
export const BOOKMARKS_FETCH_SUCCESS = 'BOOKMARKS_FETCH_SUCCESS';
export const USER_PROFILE_FETCH_FAILURE = 'USER_PROFILE_FETCH_FAILURE';
export const NO_MORE_POSTS = 'NO_MORE_POSTS';
export const NO_MORE_USER_POSTS = 'NO_MORE_USER_POSTS';
export const NO_MORE_LIKED_POSTS = 'NO_MORE_LIKED_POSTS';