import {BOOKMARKS_FETCH_SUCCESS} from "../../../actions/types";

const INITIAL_STATE = {
    lastPostCursor: null,
    scrollPosition: 0
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case BOOKMARKS_FETCH_SUCCESS:
            return {...state, lastPostCursor: action.cursor};
        default:
            return state;
    }
}