import {createContext, useContext, useEffect, useState} from 'react'
import {onAuthStateChanged} from "firebase/auth";
import {auth, firestore} from "../config/firebase";
import {doc, getDoc} from "firebase/firestore/lite";
import * as Sentry from "@sentry/node";
import get from "lodash/get";

export const UserContext = createContext()

export default function UserContextComp({children}) {
    const [user, setUser] = useState(null)
    const [userId, setUserId] = useState(null)
    const [userData, setUserData] = useState(null)
    const [userGroupIds, setUserGroupIds] = useState([])
    const [notificationCount, setNotificationCount] = useState(0)
    const [loadingUser, setLoadingUser] = useState(true) // Helpful, to update the UI accordingly.

    useEffect(() => {
        // Listen authenticated user
        const unsubscriber = onAuthStateChanged(auth, async (user) => {
            try {
                if (user) {
                    // User is signed in.
                    const {uid, displayName, email, photoURL} = user
                    const userRef = doc(firestore, 'users', uid);
                    const userDoc = await getDoc(userRef)
                    const userData = userDoc.data();
                    setUser({
                        uid,
                        email,
                        photoURL,
                        username: displayName,
                        bio: get(userData, 'bio', null)
                    })
                    setUserId(uid)
                    setUserData(userData)
                    setNotificationCount(get(userData, 'notificationCnt', 0))
                    setUserGroupIds(get(userData, 'groupIds', []))
                } else {
                    setUser(null)
                    setUserId(null)
                    setUserData(null)
                    setNotificationCount(null)
                    setUserGroupIds([])
                }
            } catch (error) {
                // Most probably a connection error. Handle appropriately.
                Sentry.captureException(error);
            } finally {
                setLoadingUser(false)
            }
        })

        // Unsubscribe auth listener on unmount
        return () => unsubscriber()
    }, [])

    return (
        <UserContext.Provider
            value={{
                userId,
                user,
                setUser,
                loadingUser,
                userData,
                notificationCount,
                setNotificationCount,
                userGroupIds,
                setUserGroupIds
            }}>
            {children}
        </UserContext.Provider>
    )
}

// Custom hook that shorthands the context!
export const useUser = () => useContext(UserContext)