import {
    NEW_POSTS_FETCH_SUCCESS,
    POPULAR_POSTS_FETCH_SUCCESS,
    SET_SCROLL_POSITION,
    TRENDING_POSTS_FETCH_SUCCESS
} from "../../../actions/types";

const INITIAL_STATE = {
    lastCursorTrending: null,
    lastCursorNew: null,
    lastCursorPopular: null,
    scrollPosition: 0
};


export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case TRENDING_POSTS_FETCH_SUCCESS:
            return {...state, lastCursorTrending: action.cursor};
        case NEW_POSTS_FETCH_SUCCESS:
            return {...state, lastCursorNew: action.cursor};
        case POPULAR_POSTS_FETCH_SUCCESS:
            return {...state, lastCursorPopular: action.cursor};
        case SET_SCROLL_POSITION:
            return {...state, scrollPosition: action.value};
        default:
            return state;
    }
}
