import {
    ASYNC_ACTION_ERROR,
    ASYNC_ACTION_FINISH,
    ASYNC_ACTION_START,
    NEW_POST_SENT_TO_FIREBASE
} from "../../actions/types";

const INITIAL_STATE = {loading: false, failed: false};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case ASYNC_ACTION_START:
            return {...state, ...INITIAL_STATE, loading: true};

        case ASYNC_ACTION_FINISH:
            return {...state, loading: false};

        case ASYNC_ACTION_ERROR:
            return {...state, loading: false, failed: true};

        case NEW_POST_SENT_TO_FIREBASE:
            return {...state, ...INITIAL_STATE};

        default:
            return state;
    }
};