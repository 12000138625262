import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import '../styles.scss'
import 'nprogress/nprogress.css'; //styles of nprogress
import React, {memo, useEffect, useRef} from 'react'
import {Provider} from 'react-redux'
import {useStore} from '../config/store'
import Router, {useRouter} from 'next/router';
import NProgress from 'nprogress'; //nprogress module
import dynamic from "next/dynamic";
import SEO from '../next-seo.config';
import {DefaultSeo} from "next-seo";
import get from 'lodash/get';
import * as Sentry from '@sentry/node'
import {RewriteFrames} from '@sentry/integrations'
import getConfig from 'next/config'
import UserProvider from "../context/userContext";
import {fetcher} from "../utilities/utils";
import {SWRConfig} from 'swr'

const ModalManager = dynamic(() => import('../features/modals/ModalManager'))
const ReduxToastr = dynamic(() => import('react-redux-toastr'))


NProgress.configure({showSpinner: false})
//Binding events.
// Router.events.on('routeChangeStart', () => NProgress.start());
Router.events.on('routeChangeComplete', () => NProgress.done());
Router.events.on('routeChangeError', () => NProgress.done());


const ROUTES_TO_RETAIN = ['/']

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
    const config = getConfig()
    const distDir = `${get(config, 'serverRuntimeConfig.rootDir', '')}/.next`
    Sentry.init({
        enabled: process.env.NODE_ENV === 'production',
        integrations: [
            new RewriteFrames({
                iteratee: (frame) => {
                    frame.filename = frame.filename.replace(distDir, 'app:///_next')
                    return frame
                },
            }),
        ],
        dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    })
}

export default function App({Component, pageProps, err}) {
    const store = useStore(pageProps.initialReduxState)

    const router = useRouter()
    const retainedComponents = useRef({})

    const isRetainableRoute = ROUTES_TO_RETAIN.includes(router.asPath)

    // Add Component to retainedComponents if we haven't got it already
    if (isRetainableRoute && !retainedComponents.current[router.asPath]) {
        const MemoComponent = memo(Component)
        retainedComponents.current[router.asPath] = {
            component: <MemoComponent {...pageProps} err={err}/>,
            scrollPos: 0
        }
    }

    // Save the scroll position of current page before leaving
    const handleRouteChangeStart = url => {
        NProgress.start()
        if (isRetainableRoute) {
            retainedComponents.current[router.asPath].scrollPos = window.scrollY
        }
    }

    // Save scroll position - requires an up-to-date router.asPath
    useEffect(() => {
        router.events.on('routeChangeStart', handleRouteChangeStart)
        return () => {
            router.events.off('routeChangeStart', handleRouteChangeStart)
        }
    }, [router.asPath])

    // Scroll to the saved position when we load a retained component
    useEffect(() => {
        if (isRetainableRoute) {
            window.scrollTo(0, retainedComponents.current[router.asPath].scrollPos)
        }
    }, [Component, pageProps])

    try {
        return (
            <Provider store={store}>
                <UserProvider>
                    <DefaultSeo {...SEO} additionalMetaTags={[{
                        name: 'theme-color',
                        content: '#008B4B'
                    }]} additionalLinkTags={[
                        {
                            rel: 'icon',
                            href: '/favicon.ico',
                        },
                        {
                            rel: 'apple-touch-icon',
                            href: '/apple-icon.png'
                        },
                        {
                            rel: 'manifest',
                            href: '/manifest.json'
                        },
                        {
                            rel: 'icon',
                            href: '/favicon-16x16.png',
                            sizes: '16x16'
                        }, {
                            rel: 'icon',
                            href: '/favicon-32x32.png',
                            sizes: '32x32'
                        },
                    ]}/>
                    <SWRConfig
                        value={{
                            refreshInterval: 1800000,
                            fetcher: fetcher,
                            onError: (error) => {
                                Sentry.captureException(error);
                            },
                        }}
                    >
                    <Component {...pageProps} err={err}/>
                    </SWRConfig>
                    <ModalManager/>
                    <ReduxToastr
                        position='bottom-right'
                        transitionIn='fadeIn'
                        transitionOut='fadeOut'
                    />
                </UserProvider>
            </Provider>
        )
    } catch (error) {
        Sentry.captureException(error);
    }
}
