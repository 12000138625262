import {combineReducers} from "redux";
import home from "../screens/main/home/HomeReducer";
import postDetail from "../screens/main/postDetails/PostDetailsReducer";
import profile from '../screens/sidemenu/profile/ProfileReducer';
import bookmarks from "../screens/sidemenu/bookmarks/reducer";
import async from '../screens/async/AsyncReducer';
import group from '../screens/sidemenu/following/group_profile/GroupReducer'
import posts from '../features/post/PostReducer';
import modalReducer from '../features/modals/modalReducer';
import authReducer from '../features/auth/authReducer';
import {reducer as FormReducer} from 'redux-form';
import {reducer as ToastrReducer} from 'react-redux-toastr';


export default combineReducers(
    {
        home,
        postDetail,
        bookmarks,
        profile,
        async,
        group,
        posts,
        modals: modalReducer,
        auth: authReducer,
        toastr: ToastrReducer,
        form: FormReducer
    }
);