const map = require("lodash/map");
const isEmpty = require("lodash/isEmpty");
const flattenDeep = require("lodash/flattenDeep");
const filter = require("lodash/filter");
const countBy = require("lodash/countBy");
const pull = require("lodash/pull");
const difference = require("lodash/difference");
const compact = require("lodash/compact");
const azureSearch = require("azure-search");


export const getTrends = async () => {

    const client = await azureSearch({
        url: process.env.SEARCH_ENDPOINT,
        key: process.env.SEARCH_KEY
    });

    const yesterday = new Date(new Date().getTime() - (24 * 60 * 60 * 1000));

    let result = await client.search('posts', {
        search: "*",
        select: "tags",
        filter: `timeStamp gt ${yesterday.toJSON()} and (diffVoteCnt ge 10)`
    });

    const daysOfTheWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday', 'God', 'Nigeria']
    const monthsOfTheYear = ['January', 'February', 'March', 'April', 'May', 'July', 'August', 'September', 'October', 'November', 'December']
    const blockedTrends = [...daysOfTheWeek, ...monthsOfTheYear]
    const checkRegEx = new RegExp(blockedTrends.join("|"), 'ig');

    let allTags = []
    let tags = map(result, 'tags')
    allTags = allTags.concat(tags)
    allTags = flattenDeep(allTags)
    allTags = filter(allTags, function (tag) {
        return /[#]*[A-Z]+/.test(tag) && (!checkRegEx.test(tag));
    });

    allTags = filter(allTags, function (tag) {
        return !(/Naija|Jesus/i.test(tag));
    });

    let tagsWithCount = countBy(allTags)

    console.log({tagsWithCount})
    let trendsWithCount = {}

    for (const [key, value] of Object.entries(tagsWithCount)) {
        if (value > 1) {
            trendsWithCount[key] = value
        }
    }

    let trends = Object.entries(trendsWithCount)
        .sort(([, a], [, b]) => b - a)
        .reduce((r, [k, v]) => ([...r, k]), []);


    const specialCharacters = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    trends = trends.filter((trend) => !specialCharacters.test(trend))

    let duplicates = []
    for (const value of trends) {
        let matches = filter(trends, function (trend) {
            return new RegExp(value, "i").test(trend)
        });
        matches = pull(matches, value)
        duplicates = duplicates.concat(matches)
    }
    trends = difference(trends, duplicates)
    trends = compact(trends)
    trends = trends.slice(0, 30)
    console.log({trends})

    return trends
}

export const search = async (searchText, limit) => {

    const client = await azureSearch({
        url: process.env.SEARCH_ENDPOINT,
        key: process.env.SEARCH_KEY
    });

    let result = await client.search('posts', {
        search: searchText,
        top: limit,
        select: "id,title,thumbnail,youtubeIds,timeStamp",
        filter: "thumbnail ne null or youtubeIds/any()",
        orderby: "hotScore desc"
    });

    result.map((post) => {
        if (isEmpty(post['thumbnail'])) {
            post['thumbnail'] = `https://i.ytimg.com/vi/${post['youtubeIds'][0]}/default.jpg`
        }
    });

    return result
}